// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.



import jQuery from 'jquery';
window.$ = window.jQuery = jQuery



import { Fancybox } from "@fancyapps/ui";
window.Fancybox = Fancybox


const fancyboxCSS =  import("@fancyapps/ui/dist/fancybox.css");

// FIX turbolinks isues
document.addEventListener('turbolinks:load', function() {
  Fancybox.bind('[data-fancybox]')
})
document.addEventListener('turbolinks:before-cache', function() {
  Fancybox.destroy()
})

// Closes the menu when following a turbolink
document.addEventListener('turbolinks:load', function() {
  if( !$('#main-navbar-toggler').hasClass('collapsed') ) {
    $('#main-navbar-toggler').click();
  }
})










require("/usr/home/janinemode/sites/www.janinemode.nl/shared/bundle/ruby/3.2.0/bundler/gems/spree_bp_core4-3e201bc35b4b/app/javascript/spree_bp_core4.js")
